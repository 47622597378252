<template>
  <v-container fluid class="pt-0 align-start fill-height">
    <v-row style="height: 100%">
      <v-col cols="12" md="12">
        <v-card-text class="pa-0" style="height: 100%">
          <v-tabs class="custom-tabs" color="success" style="height: 100%">
            <v-tab class="text-left justify-start">
              Нормативи по організації
            </v-tab>

            <v-tab-item class="pa-0" style="height: 100%">
              <v-row style="height: 100%">
                <v-col cols="12" md="12">
                  <RegularOrganizationByOrg/>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RegularOrganizationByOrg from "@/components/RegularOrganizationByOrg";

export default {
  name: "Regulars",
  components: {
    RegularOrganizationByOrg,
  }
}
</script>

<style scoped lang="scss">
.custom-tabs {
  :deep(.v-window.v-item-group.theme--light) {
    background-color: transparent;
  }

  :deep(div[role="tablist"]) {
    background-color: #f5f5f5 !important;
  }
}
</style>