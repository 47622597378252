<template>
  <v-card tile elevation="1" class="fill-height" min-height="280">
    <MainModal
        :main="{ component: 'RegularOrganization', title: 'Норматив по організації' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_regular_by_org)"
        :modal="show_regular_by_org_dialog"
        @updateItemModal="regularByOrgUpdateItemModal"
    />
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title>
          Нормативи по організації
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <YouTubeButton
            button-class="grey lighten-4 mr-2"
            icon-color="error lighten-1"
            :icon-size="26"
            href="https://youtu.be/boq7RbZl9LI"
        />
        <v-tooltip bottom color="success">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openRegularByOrgCreateDialog"
                   v-bind="attrs"
                   v-on="on"
                   class="grey lighten-4 mr-1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити новий норматив по організації</span>
        </v-tooltip>
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="dataHeaders"
        :items="dataItems"
        :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Рядків на сторінку',
                  itemsPerPageOptions: [15, 30, -1]
                }"
        class="custom-table custom-table-full custom-table-1" style="height: 100%;"
        @click:row="onRegularByOrgItemClick"
    >
      <template v-slot:item.icon="{ item }">
        <v-icon
            class="mr-2" size="26"
            :color="getAccountIcon(item.icon, 'color')"
        >
          {{ getAccountIcon(item.icon, 'icon') }}
        </v-icon>
      </template>
      <template v-slot:item.actual="{ item }">
        <v-simple-checkbox :value="item.actual" disabled/>
      </template>
      <template v-slot:item.service_name="{ item }">
        <span :class="!item.actual ? 'font-weight-normal grey--text text--darken-1' : 'font-weight-medium'">{{ item.service_name }}</span>
      </template>
      <template v-slot:item.date_start="{ item }">
        <span :class="!item.actual ? 'font-weight-normal grey--text text--darken-1' : 'font-weight-medium'">
          {{ item.date_start | formatDate }}
        </span>
      </template>
      <template v-slot:item.tariff_group_type_name="{ item }">
        <v-chip small :color="!item.actual ? 'grey lighten-2' : 'success'">
          {{ item.tariff_group_type_name || 'Без групи' }}
        </v-chip>
      </template>
      <template v-slot:item.date_end="{ item }">
        <span :class="!item.actual ? 'font-weight-normal grey--text text--darken-1' : 'font-weight-medium'">
          {{ item.date_end | formatDate }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {getAccountIcon} from "@/utils/icons";
import {mapActions, mapGetters} from "vuex";
import MainModal from "@/components/modal/MainModal";
import {SELECT_SERVICES} from "@/store/actions/services";
import {FETCH_TARIFF_GROUP_SELECT} from "@/store/actions/tariff_group";
import {FETCH_REGULAR_BY_ORGANIZATION} from "@/store/actions/regular_by_organization";

export default {
  name: "RegularOrganizationByOrg",
  components: {
    MainModal,
    YouTubeButton: () => import("@/components/YouTubeButton")
  },
  computed: {
    ...mapGetters({
      dataItems: 'getRegByOrg',
      legacy: 'isLegacy'
    })
  },
  data() {
    return {
      dataHeaders: [
        {text: '', value: 'icon', width: 26},
        {text: 'Послуга', value: 'service_name'},
        {text: 'Назва', value: 'regulator_parameters_type_name'},
        {text: 'Група', value: 'tariff_group_type_name'},
        {text: 'Початок', value: 'date_start'},
        {text: 'Закінчення', value: 'date_end'},
        {text: 'Опис', value: 'detalization'},
      ],
      selected_regular_by_org: {},
      show_regular_by_org_dialog: false,
    }
  },
  methods: {
    ...mapActions({
      fetchRegulars: FETCH_REGULAR_BY_ORGANIZATION,
      fetchServices: SELECT_SERVICES,
      fetchGroups: FETCH_TARIFF_GROUP_SELECT
    }),
    getAccountIcon,
    regularByOrgUpdateItemModal() {
      this.show_regular_by_org_dialog = false
      this.selected_regular_by_org = {}
    },
    openRegularByOrgCreateDialog() {
      this.selected_regular_by_org = {is_legacy: this.legacy}
      this.show_regular_by_org_dialog = true
    },
    onRegularByOrgItemClick(payload) {
      this.selected_regular_by_org = JSON.parse(JSON.stringify(payload))
      this.show_regular_by_org_dialog = true
    },
  },
  watch: {
    legacy: {
      immediate: true,
      handler(payload) {
        this.fetchRegulars(payload)
        this.fetchServices()
        this.fetchGroups()
      }
    }
  }
}
</script>

<style scoped lang="scss">

.custom-table {
  &:deep(.v-data-table__wrapper) {
    min-height: 300px !important;
  }
}

.custom-tabs {
  :deep(.v-window.v-item-group.theme--light) {
    background-color: transparent;
  }

  :deep(div[role="tablist"]) {
    background-color: #f5f5f5 !important;
  }
}
</style>
